import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import CheckIcon from '@mui/icons-material/Check';
import { t } from 'i18next';
import { getHexColor } from '../../../hepler';
import { useTranslation } from 'react-i18next';


function InvoiceCollapseComp({ row }) {

    const { i18n } = useTranslation();

    const TableBillingDetails = (pros) => {


        const Data = pros.data;



        return (

            <Box>



                <TableContainer component={Paper}  >

                    <Table aria-label="collapsible table">
                        <TableHead >
                            <TableRow >
                                <TableCell variant='head'>{t("PaymentMethod").toUpperCase()}</TableCell>
                                {Data.cardNumber !== "0" ? <TableCell variant='head' >{t("CardNumber").toUpperCase()}</TableCell> : ""}

                                <TableCell variant='head' >{t("ServiceType").toUpperCase()}</TableCell>

                                {Data.isHaveDefultItems ? <TableCell variant='head' >{t("MandatoryItems")}</TableCell> : ""}
                                {Data.isHaveMinimumCharge ? <TableCell variant='head' >{t("MinimumCharge")}</TableCell> : ""}

                                {Data.isHaveServices ? <TableCell >{t("AddedServices")}</TableCell> : ""}
                                {Data.isHaveTax ? <TableCell variant='head' >{t("AddedTax")}</TableCell> : ""}

                                {Data.isSelfServices ? <TableCell variant='head' >{t("SelfServices")}</TableCell> : ""}

                                {Data.tableNumber !== 0 ? <TableCell variant='head' >{t("TableNumber")}</TableCell> : ""}

                                {Data.tableNumber !== 0 ? <TableCell variant='head' >{t("NumberOfPeople")}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell variant='head' >{t("Clientloyalty")}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell variant='head' >{t("ClientName")}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell variant='head' >{t("ClientId")}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell variant='head' >{t("ClientPhone")}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell variant='head' >{t("ClientAddress")}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell variant='head' >{t("ClientNote")}</TableCell> : ""}


                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell variant='body'>{Data.PaymentMethod}</TableCell>
                                {Data.cardNumber !== "0" ? <TableCell component="th" scope="row">{Data.cardNumber}</TableCell> : ""}
                                <TableCell >{Data.ServiceName}</TableCell>

                                {Data.isHaveDefultItems ? <TableCell ><CheckIcon /></TableCell> : ""}
                                {Data.isHaveMinimumCharge ? <TableCell ><CheckIcon /></TableCell> : ""}



                                {Data.isHaveServices ? <TableCell >{Data.services}</TableCell> : ""}
                                {Data.isHaveTax ? <TableCell >{Data.tax}</TableCell> : ""}
                                {Data.isSelfServices ? <TableCell ><CheckIcon /></TableCell> : ""}

                                {Data.tableNumber !== 0 ? <TableCell >{Data.tableNumber}</TableCell> : ""}
                                {Data.tableNumber !== 0 ? <TableCell >{Data.numberOfPeople}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell ><CheckIcon /></TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell >{Data.clientName}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell >{Data.clientId}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell >{Data.clientPhone}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell >{Data.clientAddress}</TableCell> : ""}
                                {Data.isloyaltyClient ? <TableCell >{Data.clientOrderNote}</TableCell> : ""}

                            </TableRow>

                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

        )









    }



    const TableVided = (pros) => {


        const Data = pros.data;




        return (

            <Box>

                <Typography gutterBottom variant='subtitle2'>{t(Data[0].serviceName).toUpperCase()}</Typography>


                <TableContainer component={Paper}  >

                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t("Note").toUpperCase()}</TableCell>
                                <TableCell >{t("CashierName").toUpperCase()}</TableCell>
                                <TableCell >{t("CashierId").toUpperCase()}</TableCell>
                                {Data[0].serviceName === "Discounts" ? <TableCell >{t("Discount").toLocaleUpperCase() + " %"}</TableCell> : ""}
                                {Data[0].serviceName === "Refunds" ? <TableCell >{t("RefundAmount").toUpperCase()}</TableCell> : ""}
                                {Data[0].serviceName === "TaxsVoid" ? <TableCell >{t("Tax").toUpperCase()}</TableCell> : ""}

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Data.map((row, index) => (
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.note}
                                    </TableCell>
                                    <TableCell >{row.userName}</TableCell>
                                    <TableCell >{row.userId}</TableCell>
                                    {Data[0].serviceName === "Discounts" ? <TableCell >{row.discount}</TableCell> : ""}
                                    {Data[0].serviceName === "Refunds" ? <TableCell >{row.RefundAmount}</TableCell> : ""}
                                    {Data[0].serviceName === "TaxsVoid" ? <TableCell >{row.tax}</TableCell> : ""}

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Box>

        )









    }




    const ItemTable = ({ data }) => {


        const items = data.items;

        const itemImageList = data?.itemImageList;



        const ImageObject = ({id}) => {


            const value = itemImageList?.find(item => item.id === id)

            const dir = i18n.dir() ;


            if (value.color === 0) {

                return <img alt='ItemImage' src={`data:image/jpeg;base64,${value.image}`} style={{ width: "100px", height: "80px", clear: "both%", margin: "0 auto" }} />




            } else {

                return <div style={{ backgroundColor: getHexColor(value.color), width: "100px", height: "80px", clear: "both%", margin: "0 auto" ,  float: dir === "rtl" ? "right" : "left"  }} > </div>


            }


        };







        const renderAddon = ({ data, id }) => {


            if (id === 1) {

                return <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                    <Typography variant="body2">{data.description}</Typography>
                </div>

            } else if (id === 2) {


                return <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                    <Typography variant="body2"> {data.quantity} </Typography>
                </div>
            }


            return <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                <Typography variant="body2">{data.quantity * data.priceOnOneItem}</Typography>
            </div>
        };

        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Image</TableCell>
                            <TableCell>Item</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                   <ImageObject id={row.itemId} />
                                </TableCell>


                                <TableCell>
                                    <Typography variant="body1">{row.description}</Typography>
                                    {row?.billingNoteAndExtrasById?.map(billingNoteAndExtrasById => renderAddon({ data: billingNoteAndExtrasById, id: 1 }))}
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">{row.quantity}</Typography>
                                    {row?.billingNoteAndExtrasById?.map(billingNoteAndExtrasById => renderAddon({ data: billingNoteAndExtrasById, id: 2 }))}
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1">{row.quantity * row.priceOnOneItem}</Typography>
                                    {row?.billingNoteAndExtrasById?.map(billingNoteAndExtrasById => renderAddon({ data: billingNoteAndExtrasById, id: 3 }))}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };






    return (
        <Box>


            <Box>
                <Typography variant="subtitle1" gutterBottom component="div">
                    {t("Details")} :
                </Typography>
                <Divider sx={{ margin: '11px' }} />
                <TableBillingDetails data={row.Infromations.BillingInfo} />

            </Box>

            <Typography variant="subtitle1" gutterBottom component="div" sx={{ marginTop: "10px" }}>
                {t("TransactionsVoided")} :
            </Typography>
            <Divider sx={{ margin: '11px' }} />


            {row.Infromations.Defaultitems.length > 0 > 0 ?

                <Box sx={{ margin: 1 }}>
                    <TableVided data={row.Infromations.Defaultitems} />
                </Box>

                : ""}


            {row.Infromations.Discount.length > 0 > 0 ?

                <Box sx={{ margin: 1 }}>

                    <TableVided data={row.Infromations.Discount} />
                </Box>

                : ""}


            {row.Infromations.Taxs.length > 0 > 0 ?

                <Box sx={{ margin: 1 }}>

                    <TableVided data={row.Infromations.Taxs} />
                </Box>

                : ""}


            {row.Infromations.VoidMinimumCharge.length > 0 > 0 ?

                <Box sx={{ margin: 1 }}>

                    <TableVided data={row.Infromations.VoidMinimumCharge} />
                </Box>

                : ""}


            {row.Infromations.VoidServices.length > 0 > 0 ?

                <Box sx={{ margin: 1 }}>

                    <TableVided data={row.Infromations.VoidServices} />
                </Box>

                : ""}


            {row.Infromations.Refund.length > 0 > 0 ?

                <Box sx={{ margin: 1 }}>

                    <TableVided data={row.Infromations.Refund} />
                </Box>

                : ""}




<Typography variant="subtitle1" gutterBottom component="div" sx={{ marginTop: "10px" }}>
                {t("Items")} :
            </Typography>
            <Divider sx={{ margin: '11px' }} />

            <ItemTable data={row} />
        </Box>
    )
}

export default InvoiceCollapseComp