import React, { useState } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { t } from 'i18next';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse } from '@mui/material';
import moment from 'moment';
import { getServiceName, getShiftName } from '../../../hepler';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import {  useSearchParams } from 'react-router-dom';
import InvoiceCollapseComp from './InvoiceCollapseComp';


function createData(invoiceNumber, dateAndTime, dayName, casherAccount, services, shiftName, paymentMethod, netPrice, Infromations,items,itemImageList) {
    return {
        invoiceNumber,
        dateAndTime,
        dayName,
        casherAccount,
        services,
        shiftName,
        paymentMethod,
        netPrice,
        Infromations,
        items,
        itemImageList

    };
}






function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}







const headCells = [


    {
        id: 'invoiceNumber',
        numeric: false,
        disablePadding: true,
        label: 'InvoiceNumber',
    },
    {
        id: 'dateAndTime',
        numeric: true,
        disablePadding: false,
        label: 'DateAndTime',
    },

    {
        id: 'dayName',
        numeric: true,
        disablePadding: false,
        label: 'CashDay',
    },

    {
        id: 'cassherName',
        numeric: true,
        disablePadding: false,
        label: 'CashierName',
    },

    {
        id: 'services',
        numeric: true,
        disablePadding: false,
        label: 'Service',
    },
    {
        id: 'shiftName',
        numeric: true,
        disablePadding: false,
        label: 'Shift',
    },


    {
        id: 'paymentMethod',
        numeric: true,
        disablePadding: false,
        label: 'PaymentMethod',
    },


    {
        id: 'netPrice',
        numeric: true,
        disablePadding: false,
        label: 'NetAmount',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {


        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" >

                    <Checkbox
                        variant='head'

                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell>


                <TableCell padding="checkbox">

                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        variant='head'
                    >

                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {t(headCell.label).toUpperCase()}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};









function InvoicesComp(props) {

    const [searchParams, setSearchParams] = useSearchParams();
   
    const data = props?.data;
    const content = data?.billingAdapterEntities?.content;
    const page = Number(searchParams.get(("PageNumber")) || 0)
    const rowsPerPage = Number(searchParams.get(("PageSize")) || 5)
    const order = searchParams.get(("SortOrder")) || "desc"
    const orderBy = searchParams.get(("SortBy")) || "invoiceNumber"



    const setPage = (value) => {
        searchParams.set("PageNumber", value)
        setSearchParams(searchParams);
    }
    const setRowsPerPage = (value) => {
        searchParams.set("PageSize", value)
        setSearchParams(searchParams);
    }

    const setOrder = (value) => {
        searchParams.set("SortOrder", value)
        setSearchParams(searchParams);
    }
    const setOrderBy = (value) => {
        searchParams.set("SortBy", value)
        setSearchParams(searchParams);
    }







    const shopInfo = props.shopInfo;

    const rows = [];


    const [selected, setSelected] = useState([]);
    const [dense, setDense] = useState(true);
    const [openArray, setOpenArray] = useState({ Name: [] });















    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.name);


            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);

    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };
    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;


    //  const emptyRows = 0 



    const checkIOpen = (index) => {


        let resutt;
        const value = openArray.Name.indexOf(index)
        if (value > -1) {


            resutt = true;
        } else {

            resutt = false;

        }



        return resutt;

    }
    const openCollapse = (index) => {


        if (checkIOpen(index)) {
            const value = openArray.Name.indexOf(index)
            const lastArray = openArray.Name;
            lastArray.splice(value, 1); // 2nd parameter means remove one item only
            setOpenArray({ Name: lastArray })


        } else {

            const lastArray = openArray.Name;
            lastArray.push(index);

            setOpenArray({ Name: lastArray })

        }



    }

    content.forEach(element => {




        const billingNumber = moment(element.dayName, 'YYYY-MMM-DD').format("YYYY-MM-DD").replaceAll("-", "") + "-" + element.billingNumber;
        const DayName = moment(element.dayName, 'YYYY-MMM-DD').format("YYYY-MM-DD");


        let DateTimeUTC = moment(element.orderTime, "YYYY-MMM-DD HH:mm:ss").format('YYYY-MM-DD HH:mm:ss');
        DateTimeUTC = moment.utc(DateTimeUTC, 'YYYY-MM-DD HH:mm:ss')

        let DateTime = DateTimeUTC.clone().tz(shopInfo.timeZoneId)
        DateTime = DateTime.format("YYYY-MM-DD HH:mm:ss")

        const Refund = [];
        const Discount = [];
        const Taxs = [];
        const Defaultitems = [];
        const VoidServices = [];
        const VoidMinimumCharge = [];





        let CashierName = element.cashierName;
        if (CashierName === null) {
            CashierName = "ID(" + element.userId + ")";
        }

        let PaymentMethod = "Unknown";

        switch (element.paymentType) {

            case 0:

                PaymentMethod = t("Cash")
                break;

            case 1:

                PaymentMethod = "VISA CARD"
                break;


            case 2:

                PaymentMethod = "MASTER CARD"
                break;

            case 3:

                PaymentMethod = "MASTER CARD"
                break;

            default:
                // eslint-disable-next-line no-unused-vars
                PaymentMethod = "Unknown"

                break;


        }

        if (element.billingVoidServicesById.length > 0) {


        }



        element.billingVoidServicesById.forEach(data => {


            let RefundAmount = 0;



            if (data.serviceName === "Refund") {


                element.itemRefundById.forEach((itemRefund) => {

                    RefundAmount = RefundAmount + itemRefund.price;

                });

                Refund.push({ serviceName: "Refunds", note: data.note, userId: data.userId, userName: data.userName, RefundAmount })

            }

            if (data.serviceName === "Discount") {

                Discount.push({ serviceName: "Discounts", note: data.note, userId: data.userId, userName: data.userName, discount: element.discountPrice })


            }

            if (data.serviceName === "VoidTax") {
                Taxs.push({ serviceName: "TaxsVoid", note: data.note, userId: data.userId, userName: data.userName, tax: element.addedTaxPrice, canceledValue: element.tax })


            }

            if (data.serviceName === "VoidDefaultitems") {

                Defaultitems.push({ serviceName: "MandatoryItemsVoided", note: data.note, userId: data.userId, userName: data.userName, })

            }


            if (data.serviceName === "VoidServices") {
                VoidServices.push({ serviceName: "ServicesVoided", note: data.note, userId: data.userId, userName: data.userName, services: element.addedServicePrice })

            }



            if (data.serviceName === "VoidMinimumCharge") {

                VoidMinimumCharge.push({ serviceName: "MinimumChargesVoided", note: data.note, userId: data.userId, userName: data.userName, minimumCharge: element.minimumChargesPrice })

            }




        })



        let IsloyaltyClient = false;

        if (element.clientPhoneNumber !== "null") {
            IsloyaltyClient = true;

        }




        const BillingInfo = { ServiceName: getServiceName(element.priceType), PaymentMethod: PaymentMethod, isSelfServices: element.selfService, isHaveTax: element.addedTax, tax: element.addedTaxPrice, texAmount: element.tax, tableNumber: element.tabletNumber, numberOfPeople: element.numberOfPersons, isHaveServices: element.addedService, services: 0, isHaveDefultItems: false, isHaveMinimumCharge: element.haveMinimumCharge, minimumChargeAmount: element.minimumChargesPrice, isloyaltyClient: IsloyaltyClient, clientName: element.clientFirstName + " " + element.clientLastName, clientPhone: element.clientCuntryCode + "-" + element.clientPhoneNumber, clientAddress: element.clientAddress, clientId: element.clientId, clientOrderNote: element.clientOrderNote, cardNumber: element.cardNumber }


        rows.push(createData(billingNumber, DateTime, DayName, CashierName, getServiceName(element.priceType), getShiftName(element.shiftName), PaymentMethod, element.netPrice,
            {

                Refund,
                ItemRfund: element.itemRefundById,
                Discount,
                Taxs,
                Defaultitems,
                VoidServices,
                VoidMinimumCharge,
                BillingInfo,
                

            } ,element.billingItemsById , data.reportInvoiceItemList

        ))
    });



    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                            {rows
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.invoiceNumber);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow
                                                hover
                                                //onClick={(event) => handleClick(event, row.name)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={row.name}
                                                selected={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        color="primary"
                                                        onClick={(event) => handleClick(event, row.invoiceNumber)}
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>



                                                <TableCell>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        onClick={() => openCollapse(index)}
                                                    >
                                                        {checkIOpen(index) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </IconButton>
                                                </TableCell>


                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {row.invoiceNumber}
                                                </TableCell>
                                                <TableCell >{row.dateAndTime}</TableCell>
                                                <TableCell >{row.dayName}</TableCell>
                                                <TableCell >{row.casherAccount}</TableCell>
                                                <TableCell >{row.services}</TableCell>
                                                <TableCell >{row.shiftName}</TableCell>
                                                <TableCell >{row.paymentMethod}</TableCell>

                                                <TableCell >{row.netPrice}</TableCell>




                                            </TableRow>






                                            <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                                                    <Collapse in={checkIOpen(index)} timeout="auto" unmountOnExit>

                                                    <InvoiceCollapseComp row={row} />

                                                      

                                                    </Collapse>


                                                </TableCell>



                                            </TableRow>

                                        </React.Fragment>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={data.totalElements}
                    rowsPerPage={rowsPerPage}

                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}

                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                        },
                        native: true,
                    }}

                    ActionsComponent={TablePaginationActions}


                />
            </Paper>
            <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label={t("Densepadding")}
            />
        </Box>
    );
}

export default InvoicesComp